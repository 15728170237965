// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// jQuery
window.$ = require( "jquery" );
window.jQuery = require("jquery");

// jQuery UI
require("jquery-ui");

// Cookie
window.Cookies = require("js-cookie");


// Action Text
require("trix")
require("@rails/actiontext")

$(document).on("turbolinks:load", function () {
  $("[data-trix-config]").each(function (event) {
    var element = $(this);
    var config = element.attr("data-trix-config");
    var trix_id = element.attr("trix-id");
    var toolbar = $("#trix-toolbar-" + trix_id);
    var button_row = toolbar.find(".trix-button-row");

    function hideButton(button) {
      toolbar.find("[data-trix-attribute='" + button + "']").hide();
    };

    function hideGroup(group) {
      toolbar.find("[data-trix-button-group='" + group + "']").hide();
    };

    if (config == "text") {
      hideGroup("block-tools");
      hideGroup("file-tools");
      hideButton("strike");
    };

    if (config == "text-and-lists") {
      hideGroup("file-tools");
      hideButton("strike");
      hideButton("heading1");
      hideButton("quote");
      hideButton("code");

      var block_tools = toolbar.find("[data-trix-button-group='block-tools']")
      button_row.prepend(block_tools);
    };
  });
});

// Bootstrap
require("bootstrap")

// Slick
require("slick-carousel")
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

// Nested forms
require("cocoon")

// Datatables
require("datatables")
require("datatables.net-bs4")

$(document).on("turbolinks:load", function () {
  $('.datatable').DataTable({
    dom: '<"heading">frtip<"footer">',
    bInfo: false,
    autoWidth: false,
    lengthChange: false,
    columnDefs: [ {
      targets: [0,1],
      searchable: false,
      orderable: false
    } ]
  });
  $(".dataTables_filter").each(function () {
    var element = $(this);
    var input = element.find("input");
    var label = element.find("label");

    input.attr("placeholder", "Search");
    input.removeClass("form-control-sm");
    input.addClass("form-control");

    element.append(input);
    label.remove();
  });
  $(".datatable[data-datatable-heading]").each(function () {
    var element = $(this);
    var header_text = element.attr("data-datatable-heading");

    $("div.heading").html('<h1 class="h2">' + header_text + '</h1>');
  });
  $(".datatable[data-datatable-new-url]").each(function () {
    var element = $(this);
    var url = element.attr("data-datatable-new-url");
    var text = element.attr("data-datatable-new-text");

    var link = $("<a class='btn btn-primary'>");
    link.attr("href", url);
    link.text(text);

    $("div.footer").append(link);
  });
});

// Datetime picker
window.moment = require("moment");
require("tempusdominus-bootstrap-4")

// Simple Date Field
$(document).on("turbolinks:load", function () {
  $(".datetimepicker-simple.date").each(function () {
    var original_input = $(this);
    var id = original_input.attr("id");
    var is_initialized = original_input.hasClass("initialized");

    if (!is_initialized) {
      // Button
      var button = $('<div class="input-group-append">')
      var button_icon = $('<div class="input-group-text"><i class="fa fa-calendar"></i></div>');
      button.attr("data-toggle", "datetimepicker");
      button.attr("data-target", "#" + id);
      button.append(button_icon);

      // Wrapper
      var wrapper = $("<div style='position: relative;' class='input-group datetimepicker-wrapper'></div>").insertBefore(original_input);
      wrapper.append(original_input);
      wrapper.append(button);
    };
  });

  $(".datetimepicker-simple.date").datetimepicker({
    format: 'YYYY-MM-DD',
    minDate: Date()
  });
});

// Active Record Integration
var ruby_format = "YYYY-MM-DD HH:mm:ss";
var datetimepicker_format = "MM/DD/YYYY h:mm A";
var datepicker_format = "MM/DD/YYYY";
var timepicker_format = "h:mm A";

//// Initialization
function prepareDateTimeFields() {
  $(".datetimepicker-input").each(function () {
    var original_input = $(this);
    var id = original_input.attr("id");
    var is_initialized = original_input.hasClass("initialized");

    if (!is_initialized) {
      // Button
      var button = $('<div class="input-group-append">')
      if (original_input.hasClass("time")) {
        var button_icon = $('<div class="input-group-text"><i class="fa fa-clock-o"></i></div>');
      } else {
        var button_icon = $('<div class="input-group-text"><i class="fa fa-calendar"></i></div>');
      }
      button.attr("data-toggle", "datetimepicker");
      button.attr("data-target", "#" + id);
      button.append(button_icon);

      // Shadow Input
      var shadow_input = $("<input type='hidden'></input>");
      shadow_input.attr("id", id  + "_shadow");
      shadow_input.attr("name", original_input.attr("name"));

      // Wrapper
      var wrapper = $("<div style='position: relative;' class='input-group datetimepicker-wrapper'></div>").insertBefore(original_input);
      wrapper.append(original_input);
      wrapper.append(button);
      wrapper.append(shadow_input);

      if (original_input.val()) {
        var utc_time = original_input.val();
        var parsed_time = moment.utc(original_input.val(), ruby_format);

        if (original_input.hasClass("date")) {
          var formatted_time = parsed_time.local().format(datepicker_format);
        } else if (original_input.hasClass("time")) {
          var formatted_time = parsed_time.local().format(timepicker_format);
        } else {
          var formatted_time = parsed_time.local().format(datetimepicker_format);
        }

        original_input.val(formatted_time);
        shadow_input.val(utc_time);
      };

      // Save initialization
      original_input.addClass("initialized");
    };
  });
};
function initDateTimeFields() {
  $(".datetimepicker-input.time").datetimepicker({
    format: 'LT'
  });
  $(".datetimepicker-input.date").datetimepicker({
    format: 'L'
  });
  $(".datetimepicker-input:not(.time, .date)").datetimepicker();
};

$(document).on("turbolinks:load", function () {
  prepareDateTimeFields();
  initDateTimeFields();
});

//// Binding
$(document).on("input", ".datetimepicker-input", function () {
  var original_input = $(this);
  var date = original_input.hasClass("date");
  var time = original_input.hasClass("time");
  var shadow_input = $("#" + original_input.attr("id") + "_shadow");

  if (original_input.val()) {
    if (date) {
      var moment_var = moment(original_input.val(), "MM/DD/YYYY");
    } else if (time) {
      var moment_var = moment(original_input.val(), "H/mm A");
    } else {
      var moment_var = moment(original_input.val(), datetimepicker_format);
    }
    var utc_time = moment_var.utc().format(ruby_format);

    shadow_input.val(utc_time + " UTC");
  } else {
    shadow_input.val("");
  };
});

// Date Range Picker
require("daterangepicker");
var daterangepicker_locale = 'YYYY/MM/DD'
$(document).on("turbolinks:load", function () {
  $('.daterangepicker-input').daterangepicker({
    autoUpdateInput: false,
    locale: {
      cancelLabel: 'Clear',
      format: daterangepicker_locale
    }
  });

  $('.daterangepicker-input').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format(daterangepicker_locale) + ' - ' + picker.endDate.format(daterangepicker_locale));
  });

  $('.daterangepicker-input').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });
});

// Toggle Fields
function toggleFields(name, value) {
  if (typeof(value) === "boolean") {
    var value = value.toString();
  }
  var containers = $("[data-togglefields-name='" + name + "']");
  var matching_containers = containers.filter(function (index, element) {
    var element = $(element);
    var values = JSON.parse(element.attr("data-togglefields-values"));
    var values_to_s = $.map(values, function (value) { return String(value) });
    return ($.inArray(value, values_to_s) >= 0);
  });
  var other_containers = containers.filter(function (values, element) {
    var element = $(element);
    var values = JSON.parse(element.attr("data-togglefields-values"));
    var values_to_s = $.map(values, function (value) { return String(value) });
    return ($.inArray(value, values_to_s) < 0);
  });

  other_containers.hide();
  matching_containers.show();

  // Temp solution for Reservation Calendar
  matching_containers.each(function () {
    var element = $(this);
    var subset = element.attr("data-togglefields-subset")

    if (subset) {
      var radio_buttons = element.find("input[type='radio']");
      var checked_buttons = $(radio_buttons).filter(function () {
        return $(this).prop("checked");
      });

      if (checked_buttons.length < 1) {
        var first_button = radio_buttons[0];
        $(first_button).prop("checked", true);
      };
    };
  });
};

//// Configuration
function configureToggleFields() {
  var conditionalContainers = $("[data-togglefields-name]")
  conditionalContainers.hide();
  var conditionalFields = conditionalContainers.find("input, select");

  $("[data-togglefields]").each(function () {
    var element = $(this);
    if (element.attr("type") == "radio" || element.attr("type") == "checkbox") {
      toggleFields(element.data("togglefields"), element.is(":checked"));
    } else {
      toggleFields(element.data("togglefields"), element.val());
    };
  });
};

//// Differentiator (in case of matching names)
function differentiateToggleFields(scope, basename) {
  $(scope).each(function (index, item) {
    var new_value = basename + "-" + index;
    $(item).find("[data-togglefields='" + basename + "']").attr("data-togglefields", new_value);
    $(item).find("[data-togglefields-name='" + basename + "']").attr("data-togglefields-name", new_value);
  });
};

//// Update toggle fields legends
function updateToggleFieldLegends() {
  $(".nested-fields-list").each(function (index, list) {
    var list = $(list);
    var children = list.children(".nested-fields:visible");

    children.each(function (index, element) {
      var element = $(element);
      var legend = element.find(".nested-fields-legend");
      var list = element.closest(".nested-fields-list");
      var base_name = list.attr("data-nested-fields-label");

      legend.text(base_name + " #" + (index + 1))
    });
  });
}

//// Initialization
function initToggleFields() {
  // Cocoon
  differentiateToggleFields(".nested-fields", "all_day");
  differentiateToggleFields(".nested-fields", "rule_id");
  differentiateToggleFields(".nested-fields", "all_year");
  differentiateToggleFields(".nested-fields", "question_type");
  // Standard
  updateToggleFieldLegends();
  configureToggleFields();
};

$(document).on("turbolinks:load", function () {
  initToggleFields();
});

//// Bindings
$(document).on("change keyup change.datetimepicker", "[data-togglefields]", function () {
  var element = $(this);

  if (element.attr("type") == "checkbox") {
    toggleFields(element.attr("data-togglefields"), element.is(":checked"));
  } else {
    toggleFields(element.attr("data-togglefields"), element.val());
  }
});

//// Bindings: Cocoon
$(document).on("turbolinks:load", function () {
  $(".nested-fields-list").on("cocoon:after-insert", function(e, insertedItem, originalEvent) {
    differentiateToggleFields(".nested-fields", "all_day");
    differentiateToggleFields(".nested-fields", "rule_id");
    differentiateToggleFields(".nested-fields", "all_year");
    configureToggleFields();
    initDateTimeFields();
  });
  $(".nested-fields-list").on("cocoon:after-insert cocoon:after-remove", function(e, insertedItem, originalEvent) {
    updateToggleFieldLegends();
  });
});

// Image Selector
$(document).on("click", "[data-image-selector='link']", function () {
  event.preventDefault();
  var link = $(this);
  var container = link.closest("[data-image-selector='container']");
  var input = container.find("[data-image-selector='input']");

  input.click();
});

$(document).on("change", "[data-image-selector='input']", function () {
  var input = $(this);
  var container = input.closest("[data-image-selector='container']");
  var link = container.find("[data-image-selector='link']");
  var preview = container.find("[data-image-selector='preview']");
  var new_image_url = URL.createObjectURL(event.target.files[0])

  // Clear previous image
  preview.find("img").remove();
  URL.revokeObjectURL(preview.src) // free memory

  // Append new image
  var new_image = $("<img>").attr("src", new_image_url);
  preview.append(new_image);

  // Update link text
  link.text("Change Image");

  // Return focus
  link.focus();
});

// Full Calendar
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

$(document).on("turbolinks:load", function () {
  var calendar_element = document.getElementById("full-calendar");
  var height_attr = $(calendar_element).attr("data-calendar-height");
  var height = (height_attr ? height_attr + "px" : "auto");

  if (calendar_element) {
    
    if (typeof window.calendar === 'object') {
      window.calendar.destroy();
    };

    window.calendar = new Calendar(calendar_element, {
      plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
      height: height
    });
    
    $(full_calendar_events).each(function (index, item) {
      calendar.addEvent(item);
    });

    calendar.render();
  };
});

// Select All Checkbox
$(document).on("change", "input[type='checkbox'][data-check-all]", function () {
  var checkbox = $(this);
  var name = checkbox.attr("data-check-all");
  var other_checkboxes = $("input[type='checkbox'][name='" + name + "']:not([value='_all'])");
  
  if (checkbox.is(":checked")) {
    other_checkboxes.prop("checked", true);
  } else {
    other_checkboxes.prop("checked", false);
  }
});

// Set current URL
$(document).on("turbolinks:load", function () {
  var current_path = window.location.pathname;
  var links = $(".sidebar a[href='" + current_path + "']");

  links.addClass("active");
});

// Disabled links
$(document).on("click", "a[disabled='disabled']", function () {
  event.preventDefault();
});

// Sorting
$(document).on("turbolinks:load", function () {
  $(".sortable").sortable({
    stop: function (event) {
      var element = $(this);
      var items = element.find("li");
      $(items).each(function (index, element) {
        var element = $(element);
        var field = element.find("input");

        field.val(index + 1);
      });
    }
  });
})

// All Checkbox
function AllCheckbox(element) {
  var element = $(element);
  var regex_string = element.attr("data-checkbox-all");
  var matching_checkboxes = $("[id^='" + regex_string + "']")
  var checked_checkboxes = $("[id^='" + regex_string + "']:checked")

  this.update = function () {
    console.log(checked_checkboxes.length);
    element.prop("checked", checked_checkboxes.length < 1);
  };

  this.propogate = function () {
    matching_checkboxes.prop("checked", !element.is(":checked"));
  };
};

$(document).on("change", "[data-checkbox-all]", function () {
  var element = $(this);
  var object = new AllCheckbox(element);

  object.propogate();
});

$(document).on("change", "input[type='checkbox']", function () {
  var element = $(this);
  var alls = $("[data-checkbox-all]");
  var matching_alls = alls.filter(function () {
    var element_id = element.attr("id");
    var regex_string = $(this).attr("data-checkbox-all");
    var regex = new RegExp(regex_string);

    return element_id.match(regex);
  });

  matching_alls.each(function () {
    var object = new AllCheckbox(this);
    object.update();
  });
});

$(document).on("turbolinks:load", function () {
  var alls = $("[data-checkbox-all]");

  alls.each(function () {
    var element = $(this);
    var object = new AllCheckbox(element);

    object.update();
  });
});

// Language Switcher
$(document).on("click", "[data-language-switcher]", function () {
  var element = $(this);
  var language = element.attr("data-language-switcher");
  console.log(language);
  Cookies.set('language', language);

  window.location.reload(true);
});

// Reservation form
$(document).on("change.datetimepicker", "#date", function (event) {
  // change.datetimepicker fires on turbolinks:load for some reason, as well, so this condition ensures only subsequent events clear selection
  if (event["bubbles"] == true || event["oldDate"] != null) {
    $("[name='time']").prop("checked", false);
  };
});

// Number Input
$(document).on("click", '[data-number-increment]', function () {
  event.preventDefault();
  var element = $(this);
  var name = element.attr("data-number-increment");
  var target = $("[name='" + name + "']");

  if (target.val()) {
    target.val(parseInt(target.val()) + 1);
  } else {
    target.val(1);
  }
});
$(document).on("click", '[data-number-decrement]', function () {
  event.preventDefault();
  var element = $(this);
  var name = element.attr("data-number-decrement");
  var target = $("[name='" + name + "']");

  if (target.val()) {
    target.val(parseInt(target.val()) - 1);
  } else {
    target.val(-1);
  }
});
